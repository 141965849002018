import css from './App.module.scss'

import Logo from './assets/logo.svg'

function App() {
  return (
    <div className={css.Bkg}>
      <div className={css.Top}></div>
      <div className={css.Bottom}></div>

      <div className={css.Logo}>
        <img src={Logo} alt='slums.io' />
      </div>
    </div>
  )
}

export default App
